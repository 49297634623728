<template>
    <div v-if="person">
        <div class="container-fluid">
            <div class="row">
                <div class="col-7 mx-auto pb-3">
                    <h3 class="d-inline">#ヘルスチェックレポート</h3>
                    <h6 class="d-inline pl-2">
                        {{ person.relationships.facility.attributes.name ? person.relationships.facility.attributes.name + '/' : '' }} {{ person.attributes.name ? person.attributes.name + '様' : '' }}
                    </h6>
                </div>
            </div>
            <div class="row">
                <div class="col-7 mx-auto pb-3">
                    <div class="w-25">
                        <select class="custom-select" v-model="register_year">
                            <option v-for="(year,index) in years" :value="year" :key="index">{{ year }}年</option>
                        </select>
                    </div>
                </div>
            </div>
            <div class="row">
                <div class="col-7 mx-auto">
                    <table class="table table-bordered bg-white">
                        <thead class="bg-dark">
                        <tr>
                            <th>期間</th>
                            <th>表示</th>
                        </tr>
                        </thead>
                        <tbody>
                        <tr v-for="month in months">
                            <td>{{ month + '月' }}</td>
                            <td><a href="javascript:void(0)" @click="next(month)">表示</a></td>
                        </tr>
                        </tbody>
                    </table>
                </div>
            </div>
        </div>
    </div>
</template>

<script>

import ApiRouter from "@/components/Systems/ApiRouter";
import ErrorHandler from "@/components/Systems/ErrorHandler";

export default {
    name: "MedicalCooperationNursingReports",
    mixins: [ApiRouter, ErrorHandler],
    data: function () {
        return {
            person: null,
            years: [],
            months: ['1','2','3','4','5','6','7','8','9','10','11','12'],
            startYear: '2020',
            addFromThisYear: 5,
            register_year: this.$moment().format('YYYY'),
            register_month: '',
            isSelectTarget: false,
        }
    },
    created: function () {
        this.createYearOptions();
        this.loadPersonData();
    },
    methods: {
        loadPersonData: function () {
            window.axios.get(this.routes.person(this.$route.params.id))
                .then((response) => {
                    this.person = response.data.data;
                })
                .catch((error) => {

                })
        },
        next: function (month) {
            this.register_month = month;
            this.$router.push({name: 'DisplayMedicalCooperationNursingReport', query:{date:this.createRegisterDate()}})
        },
        createYearOptions: function () {
            this.years = [];
            let start = this.$moment(this.startYear);
            let end = this.$moment().add(this.addFromThisYear,'years');

            for(let year = start; year <= end; year.add(1,'years')) {
                this.years.push(year.format('YYYY'));
            }
        },
        createRegisterDate: function () {
            return this.$moment(this.register_year + '-' + this.register_month).format('YYYY-MM-DD')
        }
    }
}
</script>

<style scoped>

</style>
